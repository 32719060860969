import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/BlogLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back in the early days of the internet, it was not uncommon to hear the term “homepage” thrown around as a destination for where a person or brand lived online.`}</p>
    <p>{`Parts of the early web were wild, uncharted territory, but most of it was quite welcoming. It felt spacious. Everything was far apart and different, yet only a link away.`}</p>
    <p>{`Then things started to shift. The big business tycoons came in and staked their claims, buying out the land (attention), and encouraging the homesteaders to move to the big city (networks). The promise of social connections, recognition, and even wealth made it tempting to give up our old now, seemingly inferior, homesteads.`}</p>
    <p>{`What we didn’t know was that we were paying a high price to move to the industrialized (content) factory cities. The promise of social connections and wealth didn’t pan out for most of us.`}</p>
    <p>{`We leased our internet homes to landlords that did not care much about us beyond our purchasing power.`}</p>
    <p>{`It may be tempting to think that the old homesteader’s web is gone, but it’s not. It remains, and, while some of the tools have changed, you can still own and build your piece of the internet.`}</p>
    <p>{`This blog is `}<em parentName="p">{`my own place`}</em>{`. It’s rough around the edges right now, but it’s mine, I own it, I built it, and that feels good.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      